import React, { useEffect, useState, useCallback, useRef } from 'react';
import Meta from '../components/Meta';
import { Link, useNavigate } from 'react-router-dom';
import VideoJS from '../components/VideoPlayer';
import { TagAds, BannerAds, GridAds } from '../components/AdsComponent';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';
import axios from 'axios';
import { LazyImage, placeholder, errorPlaceholder } from '../components/LazyImage';

const DetailVideo = ({ data, relation }) => {
  const { id, title, litpic, playurl, typename, tag, body, source } = data;
  const [fullplayurl, setFullPlayUrl] = useState(null);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fileId, setFileId] = useState('');
  const [luj, setLuj] = useState('');
  const playerRef = useRef(null);
  const navigate = useNavigate(); // Define navigate hook

  // **获取视频播放 URL**
  const fetchUrlsAndTest = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      getType();
      const response = await axios.get('/c.json');
      const urlPrefixes = source === 1 ? response.data.playurl2 : response.data.playurl;

      if (!Array.isArray(urlPrefixes) || urlPrefixes.length === 0) {
        throw new Error("No valid URLs found");
      }

      setTags(tag ? tag.split(',').map(t => t.trim()) : []);
      const randomIndex = Math.floor(Math.random() * urlPrefixes.length);
      const selectedUrl = urlPrefixes[randomIndex] + playurl;
      setFullPlayUrl(selectedUrl);

      // **解析 fileId**
      const slashIndex = playurl.indexOf('/');
      const extractedFileId = playurl.substring(0, slashIndex);
      setFileId(source === 1 ? `https://8xiy4.xyz/assets/${extractedFileId}.mp4` : `https://5gmtf.xyz/mp4/${extractedFileId}.mp4`);

    } catch (e) {
      console.error('Error fetching Play URL:', e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [playurl, source]);

  useEffect(() => {
    fetchUrlsAndTest();

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [fetchUrlsAndTest]);

  // **确保 VideoJS 仅在 fullplayurl 存在时渲染**
  const videoJsOptions = fullplayurl
    ? {
        autoplay: false,
        controls: true,
        responsive: true,
        poster: litpic,
        fluid: true,
        sources: [{ src: fullplayurl, type: 'application/x-mpegURL' }],
      }
    : null;

  const handlePlayerReady = (player) => {
    if (playerRef.current) {
      playerRef.current.dispose();
    }
    playerRef.current = player;

    player.on('error', () => {
      var error = player.error();
      console.error('player error:', error.message);
      if (window.ym) {
        window.ym(94492470,'reachGoal','643733333')
      } else {
        console.warn('Yandex Metrica is not initialized.');
      }
    });
  };

  if (loading) return <LoadingText />;
  if (error) return <ErrorRetry title="视频" onClick={fetchUrlsAndTest} />;

  function getType(){
    var sys = {win:false,mac:false,x11:false,ipad:false};
    var p = navigator.platform;
    sys.win = p.indexOf("Win") == 0;
    sys.mac = p.indexOf("Mac") == 0;
    sys.x11 = p.indexOf("X11") == 0;
    sys.ipad = navigator.userAgent.match(/iPad/i) != null ? true :false;
    if(sys.win || sys.mac ||sys.x11 || sys.ipad){
      setLuj("pp");
    }else{
      var a = new Date(new Date().getTime()+(parseInt(new Date().getTimezoneOffset()/60) + 8)*3600*1000);
      var b = new Date();
      if(a.getHours() == b.getHours()){
        setLuj("mm");
      }
    }
  }

  // **修正 onc 函数**
  const onc = (n) => {
    if (luj === 'mm') {
      window.location = '/index2.html'; // Hardcoded location
    } else {
      navigate(`/${n}`); // Use navigate for routing
    }
  };

  return (
    <>
      <Meta title={`${title || '精彩视频'}-3B`} description={`在线观看视频-${title || '精彩视频'}`} keywords={`3B,${title || '精彩视频'},${tag || ''}`} />
      <BannerAds />
      <GridAds />

      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4">
        <Link to="/">首页</Link> &gt; <Link to={`/h/${typename}`}>{typename}</Link>
      </div>

      {videoJsOptions && <VideoJS key={fullplayurl} options={videoJsOptions} onReady={handlePlayerReady} />}
      {!videoJsOptions && <div className="max-w-[640px] mx-auto">抱歉，视频已下架</div>}

      <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">{title}</div>
      {body && <div className="w-full">{body}</div>}

      <div>
        <div className="px-2 my-4 tags">相关标签</div>
        {tags.map((t, index) => (
          <Link key={index} to={`/tags/${t}/`} className="tags" style={{color:'black',background:'#ff9900'}}>{t}</Link>
        ))}
      </div>

      <div style={{marginTop:'8px'}}>
        <div className="px-2 my-4 tags">推荐广告</div>
        <TagAds />
      </div>
      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm" style={{marginTop:'8px'}}>
        <a href={fileId} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">下载本片</a>
        <a href="https://x33gbuz.com" className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">地址找回</a>
      </div>

      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4 mt-4">推荐视频</div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
        {relation.map((item,index) => (
          <VideoCard key={item.id} item={item} index={index} onc={onc} />
        ))}
      </div>
    </>
  );
};

// **推荐视频卡片组件**
const VideoCard = ({ item ,index,onc}) => (
  <div className="break-inside-avoid mb-4">
    <Link to={`/${item.id}/`} className="block" onClick={index === 1 || index === 3 ? (e) => {  e.preventDefault();  onc(item.id);} : undefined}>
      <LazyImage
        className="w-full h-auto object-cover"
        src={item.litpic}
        alt={item.title}
        placeholder={placeholder(300, 200)}
        errorPlaceholder={errorPlaceholder(300, 200, item.title)}
        width={300}
        height={200}
      />
      <p className="text-center mt-2">{item.title}</p>
    </Link>
  </div>
);

export default DetailVideo;
