import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import NoHeaderFooterLayout from './layouts/NoHeaderFooterLayout';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import CategoryList from './pages/CategoryList';
import BannerDetail from './pages/BannerDetail';
import Detail from './pages/Detail';
import Tags from './pages/Tags';
import Series from './pages/Series';
import Search from './pages/Search';

// Custom hook to track page views
const usePageView = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.ym) {
      window.ym('94492470', 'hit', window.location.href, {
        title: document.title,
        referer: document.referrer,
      });
    }
  }, [location]);
};

const PageViewTracker = () => {
  usePageView();
  return null;
};

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <PageViewTracker />
        <Routes>
          <Route path="*" element={<DefaultLayout />}>
            <Route index element={<Home />} />
            <Route path=":id" element={<Detail />} />
            <Route path=":basePath/:typename/:page?" element={<CategoryList />} />
            <Route path="tags" element={<Tags />} />
            <Route path="search/:query?/:p?" element={<Search />} />
          </Route>
          <Route path="b/:id" element={<NoHeaderFooterLayout />}>
            <Route index element={<BannerDetail />} />
          </Route>
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

const DefaultLayout = () => (
  <div className="default-layout">
    <Header />
  <main className="min-h-[600px]">
      <Routes>
        <Route index element={<Home />} />
        <Route path=":id" element={<Detail />} />
        <Route path=":basePath/:typename/:page?" element={<CategoryList />} />
        <Route path="tags" element={<Tags />} />
        <Route path="search/:query?/:p?" element={<Search />} />
      </Routes>
      </main>
    <Footer />
  </div>
);

export default App;
