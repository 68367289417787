import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Meta from '../components/Meta';
import { NativeAds, BannerAds, GridAds } from '../components/AdsComponent';
import { LazyImage, placeholder, errorPlaceholder } from '../components/LazyImage';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';

const Home = () => {
  const [dataSets, setDataSets] = useState([]);
  const [rdList, setRdList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  
  // 解密函数
  const decryptData = (cipherTextBase64, ivBase64) => {
    try {
      const cipherText = CryptoJS.enc.Base64.parse(cipherTextBase64);
      const iv = CryptoJS.enc.Base64.parse(ivBase64);
      const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0");
      const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv });
      let plainText = decrypted.toString(CryptoJS.enc.Utf8).replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
      return plainText;
    } catch (e) {
      console.error("解密失败", e);
      return '';
    }
  };

  // 获取数据
  const fetchData = async () => {
    setLoading(true);
    setError(false);

    try {
      const [rdlistResponse, homeResponse] = await Promise.all([
        axios.get('/rdlist'),
        axios.get('/home')
      ]);

      if (rdlistResponse.data.status === 200) {
        const updatedRdList = rdlistResponse.data.data.map(item => {
          if (item.title && typeof item.title === 'object') {
            item.title.k1 = decryptData(item.title.k1, item.title.v2);
          }
          return item;
        });
        setRdList(updatedRdList);
      }

      if (homeResponse.data.status === 200) {
        const homeData = homeResponse.data.data.map((section, index) => {
          const key = `result${index + 1}`;
          if (section[key]?.k1) {
            const decryptedText = decryptData(section[key].k1, section[key].v2);
            return JSON.parse(decryptedText);
          }
          return [];
        });
        setDataSets(homeData);
      }
    } catch (err) {
      console.error('Error fetching Home data:', err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <LoadingText />;
  if (error) return <ErrorRetry title="首页更新" onClick={fetchData} />;

  const insertAdIntoData = (items) => {
    if (!items || items.length === 0) return []; // 确保不会返回 undefined
  
    const limitedItems = items.slice(0, 7); // 限制前7个数据项
    const randomIndex = Math.floor(Math.random() * (limitedItems.length + 1));
  
    // 只有在 `limitedItems` 不为空时才插入广告
    return limitedItems.length > 0 
      ? [...limitedItems.slice(0, randomIndex), { title: "itemAd" }, ...limitedItems.slice(randomIndex)]
      : limitedItems;
  };

  const categoryNames = ["精选视频", "大陆", "日韩", "欧美", "动漫", "三级"];
  const categoryLinks = ["", "/h/大陆/", "/h/日韩/", "/h/欧美/", "/h/动漫/", "/h/三级/"];
  
  return (
    <>
<Meta title="首页-3B" description="欢迎来到3B，这里有最精彩的在线视频，每天更新永久免费" keywords="3B, 首页, 在线视频, 每天更新, 永久免费" />
    
    {/* 如果 BannerAds 或 GridAds 失败，不影响下面的内容 */}
    <BannerAds />
    <GridAds />

    <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4 flex justify-between items-center" style={{ marginTop: '10px' }} onClick={fetchData}>
      精选视频 <span style={{ marginLeft: '20px' }}>换一批视频</span>
    </div>

    {/* 确保视频内容正常显示 */}
    <div className="content grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
      {insertAdIntoData(rdList).length > 0 ? (
        insertAdIntoData(rdList).map((item, index) => <ItemCard key={index} item={item} />)
      ) : (
        <p className="text-center w-full">暂无视频内容</p>
      )}
    </div>

    {dataSets.length > 0 ? (
      dataSets.map((data, idx) => (
        <CategorySection key={idx} title={categoryNames[idx + 1]} link={categoryLinks[idx + 1]} data={insertAdIntoData(data)} />
      ))
    ) : (
      <p className="text-center w-full">暂无视频内容</p>
    )}
    </>
  );
};

// 分类组件
const CategorySection = ({ title, link, data }) => (
  <>
    <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{ marginTop: '10px' }}>
      <Link to={link}>{title}</Link> 最新上架
    </div>
    <div className="content grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
      {data.map((item, index) => (
        <ItemCard key={index} item={item} />
      ))}
    </div>
  </>
);

// 视频卡片组件
const ItemCard = ({ item }) => {
  if (item.title === 'itemAd') {
    return <NativeAds />;
  }

  const titleText = item.title?.k1 || item.title || "未知标题";

  return (
    <div className="break-inside-avoid mb-4">
      <Link to={`/${item.id}/`} className="block">
        <LazyImage
          className="w-full h-auto object-cover"
          src={item.litpic}
          alt={titleText}
          placeholder={placeholder(300, 200)}
          errorPlaceholder={errorPlaceholder(300, 200, titleText)}
          width={300}
          height={100}
        />
        <p className="text-center mt-2 text-sm">{titleText}</p>
      </Link>
    </div>
  );
};

export default Home;
