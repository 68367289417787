import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DetailVideo from './DetailVideo';
import DetailPhotoFiction from './DetailPhotoFiction';
import { NoDetail, LoadingText } from '../components/ErrorRetry';

const Detail = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [relation, setRelation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // 解密方法
  const decryptAES = (cipherTextBase64, ivBase64) => {
    try {
      const cipherText = CryptoJS.enc.Base64.parse(cipherTextBase64);
      const iv = CryptoJS.enc.Base64.parse(ivBase64);
      const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定密钥
      const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv });
      return decrypted.toString(CryptoJS.enc.Utf8).replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
    } catch (error) {
      console.error("解密失败:", error);
      return "";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await axios.get(`/detail/${id}`);

        if (response.data.status === 200) {
          const result1 = response.data.data[0]?.result1;
          const result2 = response.data.data[1]?.result2 || [];

          if (!result1 || !result1.k1 || !result1.v2) {
            throw new Error("数据格式错误");
          }

          // 解密主视频数据
          const jsonData = JSON.parse(decryptAES(result1.k1, result1.v2));
          setData(jsonData);

          // 处理关联数据
          const updatedData = result2.map((item) => {
            if (item.title && typeof item.title === 'object') {
              return { ...item, title: decryptAES(item.title.k1, item.title.v2) };
            }
            return item;
          });

          setRelation(updatedData);
        } else {
          throw new Error("API 请求失败");
        }
      } catch (error) {
        console.error("获取详情数据失败:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) return <LoadingText />;
  if (error || !data) return <NoDetail message="找不到任何内容" />;

  const { typename } = data;

  if (['大陆', '三级', '日韩', '欧美', '动漫', '短片'].includes(typename)) {
    return <DetailVideo data={data} relation={relation} />;
  } else if (['小说', '漫画', '色图', '图片'].includes(typename)) {
    return <DetailPhotoFiction data={data} />;
  } else {
    return <NoDetail message="本内容已下架" />;
  }
};

export default Detail;
